<template>
      <div class="homepage">
            <img v-for="(img, i) in images" :key="i" :class="img.name" :data-wow-offset="10 + i" class="top-zero pos-abs wow block animate__animated animate__fadeInDown" v-lazy-src="require(`@/assets/images/desktop/png/${img.name}.png`)" :alt="img.name">
      </div>
</template>

<script>
      import directive from '@/directives/lazy-src';
      import { WOW } from 'wowjs';

      export default {
            name: 'homepage',
            directives: {directive},
            data() {
                  return {
                        images: [
                              {name: "home-1"},
                              {name: "home-2"},
                              {name: "home-3"},
                              {name: "home-4"},
                              {name: "home-5"},
                              {name: "home-6"},
                              {name: "home-7"},
                              {name: "home-8"},
                              {name: "home-9"},
                              {name: "home-10"},
                              {name: "home-11"},
                              {name: "what-is-your-motto"},
                        ]
                  }
            },
            mounted() {
                  var wow = new WOW();
                  wow.init();
            },
      }
</script>

<style lang='scss'>
@import 'Homepage';
</style>