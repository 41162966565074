<template>
      <Homepage/>
</template>

<script>
      import Homepage from '@/components/pages/homepage/Homepage';
      export default {
            name: 'home-page',
            components: {Homepage}
      }
</script>

<style lang='scss'>
</style>